const collapse = {
  bind(el) {
    el.addEventListener('click', (e) => {
      let slideOpen = true;
      let heightChecked = false;
      let initHeight = 0;
      let initPaddingTop = 0;
      let initPaddingBottom = 0;

      function slideToggle(ele) {
        const element = ele;
        if (!heightChecked) {
          initHeight = parseFloat(window.getComputedStyle(el, null).getPropertyValue('height').replace('px', ''));
          initPaddingTop = parseFloat(window.getComputedStyle(el, null).getPropertyValue('padding-top').replace('px', ''));
          initPaddingBottom = parseFloat(window.getComputedStyle(el, null).getPropertyValue('padding-bottom').replace('px', ''));

          // initHeight -= initPaddingTop + initPaddingBottom;
          element.style.height = initHeight;
          element.style.paddingTop = initPaddingTop;
          element.style.paddingBottom = initPaddingBottom;
          heightChecked = true;
        }
        if (slideOpen) {
          element.style.overflow = 'hidden';
          const interval = setInterval(() => {
            const h = window.getComputedStyle(el, null).getPropertyValue('height').replace('px', '') - initHeight / 20;
            const pt = window.getComputedStyle(el, null).getPropertyValue('padding-top').replace('px', '') - initPaddingTop / 20;
            const pb = window.getComputedStyle(el, null).getPropertyValue('padding-bottom').replace('px', '') - initPaddingBottom / 20;
            element.style.height = `${h}px`;
            element.style.paddingTop = `${pt}px`;
            element.style.paddingBottom = `${pb}px`;
            if (h <= 0) {
              clearInterval(interval);
              element.style.display = 'none';
              element.style.height = '';
              element.style.paddingTop = '';
              element.style.paddingBottom = '';
              element.style.overflow = '';
            }
          }, 1);
          slideOpen = false;
        } else {
          element.style.overflow = 'hidden';
          let h = 0;
          let pt = 0;
          let pb = 0;
          element.style.height = `${h}px`;
          element.style.paddingTop = `${pt}px`;
          element.style.paddingBottom = `${pb}px`;
          const interval = setInterval(() => {
            h += initHeight / 20;
            pt += initPaddingTop / 20;
            pb += initPaddingBottom / 20;
            element.style.height = `${h}px`;
            element.style.paddingTop = `${pt}px`;
            element.style.paddingBottom = `${pb}px`;
            if (h >= initHeight) {
              clearInterval(interval);
              element.style.height = '';
              element.style.paddingTop = '';
              element.style.paddingBottom = '';
              element.style.overflow = '';
            }
          }, 1);
          element.style.display = '';
          slideOpen = true;
        }
      }
      const card = el.closest('.card');
      card.classList.toggle('card-collapsed');
      slideToggle(card.querySelector('.card-body'));
      el.querySelector('i').classList.toggle('collapsedRotate');
      e.stopPropagation();
    });
  },
};

export default collapse;
