import axios from '@/plugins/axios';

class InvoiceService {
  getInvoices(data) {
    this.data = data;
    return axios().get('invoices', { params: this.data }).then((response) => response.data);
  }

  getInvoice(id) {
    this.a = 'a';
    return axios().get(`invoices/${id}`);
  }

  createInvoice(data) {
    this.data = data;
    return axios().post('invoices', this.data).then((response) => response.data);
  }

  deleteInvoice(id) {
    this.a = id;
    return axios().delete(`invoices/${id}`, this.data).then((response) => response.data);
  }
}

export default new InvoiceService();
