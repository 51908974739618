<template>
  <div class="content">
    <PageHeader title="Adicionar Crédito" />
    <div class="page-content container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header" role="button">
              Insira os dados
              <ul class="actions top-right">
                <li>
                  <a href="javascript:void(0)">
                    <i class="icon dripicons-chevron-down"></i>
                  </a>
                </li>
              </ul>
            </div>
            <form @submit.prevent="upgrade">
              <div class="card-body">
                <div class="row">
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <div class="card card-border-info">
                      <div class="card-header">Adicionar créditos</div>
                      <div class="card-body">
                        <div class="form-group">
                          <label for="companyName">Valor a adicionar à sua conta</label>
                          <input type="text" class="form-control" v-model="form.value" id="companyName" required v-money="money" v-bind:class="{'invalid':invalidValue}"/>
                          <div class="invalid-field" v-if="invalidValue">{{invalidValueMessage}}</div>
                          <small
                            id="emailHelp3"
                            class="form-text text-muted"
                          >O valor mínimo de R$ 100,00 e valor máximo de R$ 5.000,00</small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-footer bg-light">
                <button type="submit" class="btn btn-primary"
                  :class="{'qt-loader qt-loader-mini qt-loader-right':isSending}"
                  :disabled="isSending">{{$t('generic-str.add')}}</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { mask } from 'vue-the-mask';
import BillingService from '@/services/invoices.service';
import Collapse from '@/directives/Collapse';
import PageHeader from '@/components/PageHeader.vue';

export default {
  name: 'Billing',
  directives: {
    Collapse,
    mask,
  },
  components: {
    PageHeader,
  },
  data() {
    return {
      cnpjValid: true,
      searching: false,
      isSending: false,
      invalidValue: false,
      invalidValueMessage: '',
      form: {
        value: '',
      },
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        precision: 2,
        masked: false,
      },
    };
  },
  computed: {
    account() {
      return this.$store.state.account;
    },
  },
  methods: {
    upgrade() {
      this.invalidValue = false;
      this.form.value = this.form.value.replace('R$', '');
      this.form.value = this.form.value.replace(',', '');
      this.form.value = this.form.value.replace('.', '');
      this.form.value = this.form.value.replace(' ', '');
      if (this.form.value < 100) {
        this.invalidValue = true;
        this.invalidValueMessage = 'O valor deve ser maior do que R$ 100,00';
        return;
      }
      console.log(parseFloat(this.form.value));
      if (this.form.value > 500000) {
        this.invalidValue = true;
        this.invalidValueMessage = 'O valor deve ser menor do que R$ 5.000,00';
        return;
      }
      this.isSending = true;
      if (!this.invalidValue) {
        console.log({ value: parseFloat(this.form.value) });
        BillingService.createInvoice({ value: parseFloat(this.form.value) })
          .then(
            (rest) => {
              console.log(rest);
              this.isSending = false;
              this.$toast.show({
                title: this.$t('generic-str.success'),
                content: 'Conta atualizada',
                type: 'success',
              });
              this.$router.push('/invoices');
            },
            (error) => {
              this.isSending = false;
              console.log(error);
              this.$toast.show({
                title: `${this.$t('sms.infos.filters.status.lbl-error')} ${error.code}`,
                content: error.response.data.message,
                type: 'danger',
              });
            },
          );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.form-group .preloader {
  right: 20px;
  position: absolute;
  bottom: 20px;
}
</style>
